/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import "../node_modules/bootstrap/dist/css/bootstrap.css";

// @import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
// @import "~font-awesome/css/font-awesome.min.css";


  //SHRA theme colors
  .bg-shradarkblue{
    background-color:#223066;
   }
 
   .bg-shralightblue{
     background-color: #22a7e0;
   }
 
   .bg-shradarkorange{
   background-color: #f05a22;
   }
 
   .bg-shralightorange{
    background-color: #f69220;
   }
 
   .bg-shradarkgreen{
   background-color: #00713c;
   }
 
   .bg-shralightgreen{
   background-color: #8bc440;
   }
 
   .bg-shrared{
     background-color: #b60c0c;
   }

   
//infotext
.infoMessage {
    font-size: medium;
    font-weight: bold;
    color: tomato;
}

.infoTextSmall {
    font-size: small;
}

.infoTextMedium {
    font-size: medium;
}

.infoTextLarge {
    font-size: large;
}

.errormsg{
    margin-bottom: 0px; 
    color:#860707;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
  }

  .errormsgBig{
    margin-bottom: 0px; 
    color:#860707;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2em;
  }

  .infomsg{
    margin-bottom: 0px; 
    color:#350606;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
  }

  .lnk-text{
    color:white;
    text-decoration: none;
    cursor: pointer;
  }


  .lnk-text-green{
    color:#00713c;
    text-decoration: none;
    cursor: pointer;
  }


  .lnk-text-dark-green{
    color:#00713c;
    text-decoration: none;
    cursor: pointer;
    text-decoration-line: underline;
  }
  
  .lnk-text-black{
    text-decoration: none;
    cursor: pointer;
  }

  .lnk-text-light-green{
    color:#8bc440;
    text-decoration: none;
    cursor: pointer;
  }

  .lnk-text-light-blue{
    color:#22a7e0;
    text-decoration: none;
    cursor: pointer;
  }

  .lnk-text-dark-blue{
    color:#223066;
    text-decoration: none;
    cursor: pointer;
  }

  .lnk-text-orange{
    color:#f05a22;
    text-decoration: none;
    cursor: pointer;
  }

  .page-title {
	  color: #00713c;
    font-weight: bolder;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }


  .page-subtitle {
    color: #00713c;
    font-weight: bolder;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
  }    
  
  .page-subtitle1 {
    color: #00713c;
    font-weight: bolder;
    font-size: 1.2rem;
  } 
  
  .page-subtitle2 {
    color: #00713c;
    font-weight: bolder;
    font-size: 1rem;
  } 
  
  .page-text {
    color: #00713c;
    font-weight: bold;
    font-size: 1rem;
  }   
  
  .page-text-sm {
    color: #00713c;
    font-weight: bold;
    font-size: 0.8rem;
  }  

  .text-warning{
    color: #f05a22;
  }

  .text-search{
    color: #f05a22;
  }

  .text-lgreen{
    color: #8bc440;
  }

  .info-field-label{
    color: #00713c;
    font-weight: bold;
    font-size: 0.9rem;
  }

  .info-field-label-sm{
    color: #00713c;
    font-weight: bolder;
    font-size: 0.7rem;
  }

  .infotext{
	color: #22a7e0;
  }

  .text-sm {
    font-weight: bold;
    font-size: 0.9rem;
  }  

  h2 {
    color: #00713c;
  }


  .tooltipMy {
    position: relative;
    display: inline-block;
    border:#00713c solid 3px;
    visibility: visible;
    min-width: 300px;
    background-color: white;
    color: #00713c;
    text-align: center;
    padding: 5%;
    border-radius: 20px;
    position: absolute;
    z-index: 1;
  }


  .tooltipMy:hover .tooltiptext {
    visibility: visible;
  }

  .tooltipMy:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left:50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #f1f1f1 transparent;
  }

  .tooltipMy.show {
    visibility: visible;
    opacity: 1;
  }


  .accordion {
    background-color: #00713c;
    color: #ffffff;
    cursor: pointer;
    padding: 10px;
    width: 90%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 1,3en;
    transition: 0.4s;
    margin-bottom: 1em;
    margin-left: 5%;
  }
  
  .active, .accordion:hover {
    background-color: #ccc;
    color: #00713c;
  }
  
  .textblue{
    color: #233066;
  }

.img-expand{
  transition: transform .2s;
}

.img-expand:hover{
  cursor: zoom-in;
  // transform: scale(1.5);
}

.img-shrink{
  transition: transform .2s;
}

.img-shrink:hover{
  cursor: zoom-out;
  // transform: scale(1.5);
}

.imgbutton:hover{
  cursor: pointer;
}

.faq-panel{
  position:fixed; 
  bottom: 10px; 
  left:50%; 
  margin:0; 
  transform: translate(-50%); 
  height: 90%; 
  width: 90%;
}

//form and controls
  .form{
    padding: 1rem;
    background: rgb(255,255,255);
    opacity: 0.9;
    height: 75%;

    margin: 40px;
    margin-top: 5%;
    margin-bottom: 5%;
  }


  .control-label{
    font-weight: bold;
    font-size: large;
    // font-style: italic;
    margin-bottom: 0;
    color: #00713c;
  }

  .form-control{
    // font-weight: bold;
    font-size: large;
    margin-bottom: 10px;
    color: black;
  }


  select.form-control {
    -webkit-appearance: menulist;
}

  .radio-inline{
        width: 20px;
        height: 20px;
  }

  .checkbox{
    width: 20px;
    height: 20px;
  }

  .checkbox-label, .option-label, .field-label{
    color: black;
    font-weight: normal;
  }

  .form-control.ng-touched.ng-invalid{
    border-color:red;
    box-shadow: 0 4px 9px 0 rgb(102, 2, 2);
  }
  
  .whitespacenowrap{
    white-space: nowrap;
  }

  .text-left {
    text-align: left;
  }

  .table-content{
    font-size: 0.9rem;
    font-weight: bold;
    vertical-align: middle;
  }
  
  .lnbr {
    white-space: pre-line;
   }


  .overflow-scroll {
    max-height: 400px;
    min-width: 500px;
    overflow-y: scroll;
  }

  .boxlayout {
    background-color: white ;
    border: 2px solid black;
    padding: 10px;
    margin: 10px;
  
    display: inline-block;
    vertical-align: top;
  }

  [aria-expanded="true"] .bi-caret-right-fill,
  [aria-expanded="false"] .bi-caret-down-fill {
    display:none;
  }

  .dropdown-menu, .dropdown-item:hover {
    color: white;
    background: #8bc440;
    border-radius: 20px;
  }

  .dropdown-menu a:hover {
    color: white;
    background: #00713c;
    border-radius: 0px;
  }

  .dropdown-item:first-child:hover{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .dropdown-item:last-child:hover {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .slide-right, .slide-left {
    width: 100%;
  }
  
  .slide-up, .slide-down {
    height: 100%;
  }

  /***** Slide Right *****/
  .slide-right {
    animation: 1s slide-right;
  }
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  
  /***** Slide Left *****/
  .slide-left {
    animation: 1s slide-left;
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
    
    /***** Slide up *****/
    .slide-up {
      animation: 1s slide-up;
    }
    @keyframes slide-up {
      from {
        margin-top: 100%;
      }
      to {
        margin-top: 0%;
      }
    }
    
    /***** Slide down *****/
    .slide-down {
      animation: 1s slide-down;
    }
    @keyframes slide-down {
      from {
        margin-top: -100%;
      }
      to {
        margin-top: 0%;
      }
    }


  /***** FadeIn Underline *****/
  .line {
    border-bottom: 3px solid red;
    width: 200px;
    margin: auto;
  }
  .fade-in {
    animation: fadeIn ease 3s;
  }
  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }


/*======= BUTTONS STYLES  =======*/
  .btn-radius {
    border-radius: 100px !important;
  }


  .btn {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 2px;
    padding: 13px 28px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
    text-transform: uppercase;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }

  .btn-sm {
    font-size: 11px;
    font-weight: 600;
    padding: 11px 25px;	
  }

  .btn-lg {
    padding: 18px 49px;
  }

  .btn-xs {
    font-size: 10px;
    padding: 8px 14px;
  }

  .btn-header {
    padding: 8px 16px;	
  }

  .btn-general{
    background-color: #f69220;
    background-size: 400% 400%;
    border: medium none;
    color: #fff !important;
  }

  .btn-general:hover, .btn-general:active, .btn-general:focus{
    background-color: #f05a22;
  }

  .btn-edit{
    background-color: #22a7e0;
    background-size: 400% 400%;
    border: medium none;
    color: #fff !important;
  }

  .btn-edit:hover, .btn-edit:active, .btn-edit:focus{
    background-color: #223066;
  }

  .btn-save{
    background-color: #8bc440;
    background-size: 400% 400%;
    border: medium none;
    color: #fff !important;
  }

  .btn-save:hover, .btn-save:active, .btn-save:focus{
    background-color: #00713c;
  }

  .btn-cancel{
    background-color: #f69220;
    background-size: 400% 400%;
    border: medium none;
    color: #fff !important;
  }

  .btn-cancel:hover, .btn-cancel:active, .btn-cancel:focus{
    background-color: #f05a22;
  }

  .btn-delete{
    background-color: red;
    background-size: 400% 400%;
    border: medium none;
    color: #fff !important;
  }

  .btn-delete:hover, .btn-delete:active, .btn-delete:focus{
    background-color: darkred;
  }

  .btn-light-green{
    background-color: #8bc440;
    background-size: 400% 400%;
    border: medium none;
    color: #fff !important;
  }

  .btn-light-green:hover, .btn-light-green:active, .btn-light-green:focus{
    background-color: #00713c;
  }

  .btn-disabled:disabled{
    background: rgb(43, 42, 42);
    background-size: 400% 400%;
    border: medium none;
    color: white;
  }

  
  .btn-primary {
    background: #5a7ce2;
    background: -moz-linear-gradient(-45deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
    background: -webkit-linear-gradient(-45deg, #5a7ce2 0%,#8283e8 50%,#5c5de8 51%,#565bd8 71%,#575cdb 100%);
    background: linear-gradient(135deg, #5a7ce2 0%,#8283e8 50%,#5c5de8 51%,#565bd8 71%,#575cdb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a7ce2', endColorstr='#575cdb',GradientType=1 );
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
  }

  .btn-secondary{
    background: #797b7f;
    background: -moz-linear-gradient(-45deg, #797b7f 0%, #b5b8bf 50%, #8e9397 51%, #8e9397 71%, #828589 100%);
    background: -webkit-linear-gradient(-45deg, #797b7f 0%,#b5b8bf 50%,#8e9397 51%,#8e9397 71%,#828589 100%);
    background: linear-gradient(135deg, #797b7f 0%,#b5b8bf 50%,#8e9397 51%,#8e9397 71%,#828589 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#797b7f', endColorstr='#828589',GradientType=1 );
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
  }

  .btn-success{
    background: #05ac50;
    background: -moz-linear-gradient(-45deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
    background: -webkit-linear-gradient(-45deg, #05ac50 0%,#21dd72 50%,#05c44e 51%,#05ac50 71%,#05ac50 100%);
    background: linear-gradient(135deg, #05ac50 0%,#21dd72 50%,#05c44e 51%,#05ac50 71%,#05ac50 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05ac50', endColorstr='#05ac50',GradientType=1 );
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
  }

  .btn-danger{
    background: #e81216;
    background: -moz-linear-gradient(-45deg, #e81216 0%, #f45355 50%, #f6290c 51%, #ed0e11 71%, #fc1b21 100%);
    background: -webkit-linear-gradient(-45deg, #e81216 0%,#f45355 50%,#f6290c 51%,#ed0e11 71%,#fc1b21 100%);
    background: linear-gradient(135deg, #e81216 0%,#f45355 50%,#f6290c 51%,#ed0e11 71%,#fc1b21 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e81216', endColorstr='#fc1b21',GradientType=1 );
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
  }

  .btn-warning{
    background-color: #f69220;
    background: -moz-linear-gradient(-45deg, #e5ae09 0%, #ffd044 50%, #ffc107 51%, #fc9014 71%, #f1890b 100%);
    background: -webkit-linear-gradient(-45deg, #e5ae09 0%,#ffd044 50%,#ffc107 51%,#fc9014 71%,#f1890b 100%);
    background: linear-gradient(135deg, #e5ae09 0%,#ffd044 50%,#ffc107 51%,#fc9014 71%,#f1890b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5ae09', endColorstr='#f1890b',GradientType=1 );
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
    color: #fff !important;
  }


  .btn-info{
    background: #209dd8;
    background: -moz-linear-gradient(-45deg, #01a8c1 0%, #2adbf7 50%, #00b5d1 51%, #0aafc9 71%, #0599b1 100%);
    background: -webkit-linear-gradient(-45deg, #01a8c1 0%,#2adbf7 50%,#00b5d1 51%,#0aafc9 71%,#0599b1 100%);
    background: linear-gradient(135deg, #01a8c1 0%,#2adbf7 50%,#00b5d1 51%,#0aafc9 71%,#0599b1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01a8c1', endColorstr='#0599b1',GradientType=1 );
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
    color: #fff !important;  
  }

  .btn-light{
    background: #f2f2f2;
    background: -moz-linear-gradient(-45deg, #f2f2f2 0%, #dddddd 50%, #ffffff 51%, #ffffff 71%, #f6f8fb 100%);
    background: -webkit-linear-gradient(-45deg, #f2f2f2 0%,#dddddd 50%,#ffffff 51%,#ffffff 71%,#f6f8fb 100%);
    background: linear-gradient(135deg, #f2f2f2 0%,#dddddd 50%,#ffffff 51%,#ffffff 71%,#f6f8fb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#f6f8fb',GradientType=1 );
    color: #3f345f !important;
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
  }

  .btn-dark{
    background: #343a40;
    background: -moz-linear-gradient(-45deg, #343a40 0%, #667584 50%, #4e5256 51%, #242a30 71%, #343a40 100%);
    background: -webkit-linear-gradient(-45deg, #343a40 0%,#667584 50%,#4e5256 51%,#242a30 71%,#343a40 100%);
    background: linear-gradient(135deg, #343a40 0%,#667584 50%,#4e5256 51%,#242a30 71%,#343a40 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#343a40', endColorstr='#343a40',GradientType=1 );
    background-size: 400% 400%;
      // -webkit-animation: AnimationName 5s ease infinite;
      // -moz-animation: AnimationName 5s ease infinite;
      // animation: AnimationName 5s ease infinite;
    border: medium none;
  }



@-webkit-keyframes AnimationName {
  0%{
      background-position:0% 31%
  }
  50%{
      background-position:100% 70%
  }
  100%{
      background-position:0% 31%
  }
}
@-moz-keyframes AnimationName {
  0%{
      background-position:0% 31%
  }
  50%{
      background-position:100% 70%
  }
  100%{
      background-position:0% 31%
  }
}
@keyframes AnimationName {
  0%{
      background-position:0% 31%
  }
  50%{
      background-position:100% 70%
  }
  100%{
      background-position:0% 31%
  }
}


  @media only screen and (max-width: 572px) {
      .container{
        width: 100%;
      }

  }
